import React, { useEffect } from "react";
import Layout from "@coursemaker/gatsby-theme-coursemaker/src/components/layout";
import Courses from "@coursemaker/gatsby-theme-coursemaker/src/components/courses";
import {navigate} from "gatsby";
import { isAuthenticated, coursesFromJWT } from "../auth/auth";


const CoursesPage = ({ pageContext }) => {
  useEffect(() => {
    if (!isAuthenticated()){
      navigate('/login')
    }
  })
  const courses = pageContext.courses;
  let schoolThemeStyle = pageContext.school?.schoolThemeStyle;
  if (!schoolThemeStyle) {
    schoolThemeStyle = {
      primaryColor: 'blue',
      secondaryColor: 'blue',
    };
  }
  let displayCourses = [];

  const fetchCourses = () => {
    let enrolledCourses = coursesFromJWT()
    if (enrolledCourses) {
      displayCourses = courses.filter((course) => enrolledCourses.includes(parseInt(course.id)))
    }
    return displayCourses;
  }

  displayCourses = fetchCourses();
  let explanation;
  if(displayCourses.length === 0){
    explanation = "You have not purchased any courses or memberships."
  } else {
    explanation = ""
  }

  return (
    <Layout pageContext={pageContext} schoolThemeStyle={schoolThemeStyle}>
      <Courses courses={displayCourses} paid={true} />
      <p className="mt-5 text-center">{explanation}</p>
    </Layout>
  );
};

export default CoursesPage;
